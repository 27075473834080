import '../styles/index.scss';

import lozad from 'lozad';
if (!('IntersectionObserver' in window)) {
  var script = document.createElement('script');
  script.src = 'https://raw.githubusercontent.com/w3c/IntersectionObserver/master/polyfill/intersection-observer.js';
  document.getElementsByTagName('head')[0].appendChild(script);
}
const throttle = require('lodash.throttle');

// Lazy loads elements with default selector as '.lozad'
const observer = lozad();
observer.observe();

// Cookies consent
const close = document.getElementById('Close');
const cookies = document.getElementById('Cookies');
const isAccepted = localStorage.getItem('cookieConsent');

if (localStorage.getItem('cookieConsent') === null) { cookies.classList.add('Display') };

close.onclick = function(e) {
  e.stopPropagation();
  cookies.style.transition = 'all 0.5s cubic-bezier(.57,.21,.69,1.25)';
  cookies.classList.remove('Display');
  localStorage.setItem('cookieConsent', true);
  setTimeout(function(){ cookies.remove() }, 550);
};

// Navigation desktop
let yPos = 0;
const navigation = document.getElementById('Navigation');

function pageYPos() {
  const yPos = window.scrollY;
  if (yPos > 300) {
    navigation.classList.add('Active');
  } else {
    navigation.classList.remove('Active');
  }
};

window.addEventListener('scroll', throttle(pageYPos, 1000));


// Navigation mobile
const navmobile = document.getElementById('Mobile__main');
const burger = document.getElementById('Mobile__open');

burger.onclick = function(e) {
  e.stopPropagation();
  let isOpen = navmobile.hasAttribute('nav-open');
  if (!isOpen) {
    navmobile.setAttribute('nav-open', '');
  }
  if (isOpen) {
    navmobile.removeAttribute('nav-open');
  }
};


